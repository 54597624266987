<template>
    <el-dialog
        title="回访"
        :visible.sync="visible"
        width="650px"
    >
        <el-form ref="form" :model="form" label-position="left" label-width="130px">
            <el-form-item label="回访时间" prop="repairFollow.followUpTime" :rules="repairFollowRules.followUpTime">
                <el-date-picker
                    v-model="form.repairFollow.followUpTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择"
                    class="full-width"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="回访状态" prop="repairFollow.followUpStatus" :rules="repairFollowRules.followUpStatus">
                <el-radio-group v-model="form.repairFollow.followUpStatus" @change="handleFollow">
                    <el-radio v-for="option of followUpStatusOptions" :label="option.id" :key="option.id" >
                        <el-tag :type="option.type">{{ option.name }}</el-tag>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否发生过维修" prop="repairFollow.repairOccurred" :rules="repairFollowRules.repairOccurred">
                <el-radio-group v-model="form.repairFollow.repairOccurred">
                    <el-radio :label="1" :disabled="form.repairFollow.followUpStatus === 1">
                        <el-tag type="primary">是</el-tag>
                    </el-radio>
                    <el-radio :label="0">
                        <el-tag type="success">否</el-tag>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary" :loading="loading" @click="handleApproval"> 确认 </el-button>
        </span>
    </el-dialog>
</template>

<script>
import { followUpStatusOptions } from "@/constant/options"
import { repairActionEnum, repairAuditStatusEnum } from "@/constant/enum"

export default {
    name: 'FollowUpConfirm',
    props: { },
    data() {
        return {
            visible: false,
            loading: false,
			apiUrl: 'claimAuthRepairOrderFollow',
            form: {
                id: "",
                repairFollow: {
                    followUpTime: "",       // 回访时间
                    followUpStatus: "",     // 1：未接听，2：已接听不满意，3：已接听满意
                    repairOccurred: "",     // 是否发生过维修 0：否，1：是
                }
            },
            repairFollowRules: {
                followUpTime: [
                    { required: true, message: "请选择回访时间", trigger: "change" },
                ],
                followUpStatus: [
                    { required: true, message: "请选择回访状态", trigger: "change" },
                ],
                repairOccurred: [
                    { required: true, message: "请选择是否发生过维修", trigger: "change" },
                ],
            },
            repairActionEnum,
            repairAuditStatusEnum,
            followUpStatusOptions,
        }
    },
    methods: {
        show(type,data) {
			this.loading = false
            this.$refs.form && this.$refs.form.resetFields()
            this.form.id = data.relatedId
			// 编辑回访
			if (type == 'edit' && data) {
				this.form.repairFollow = data
			}
            this.$nextTick(() => {
				this.visible = true
			})
        },
        handleCancel() {
            this.visible = false
        },
        handleApproval() {
            let that = this
            that.$refs['form'].validate((valid, fields) => {
                if (!valid) {
                    return
                }

                that.loading = true

                that.$request.post(
                    that.apiUrl,
                    false,
                    that.form,
                    function (r) {
                        if (r.code == "0") {
                            that.visible = false
                            that.$emit("refresh")
                        } else {
							that.loading = false
                            that.$message.error(r.msg)
                        }
                    },
                    function () {
						that.loading = false
                    }
                )
            })
        },
		handleFollow(row) {
			if (row === 1) {
				this.form.repairFollow.repairOccurred = 0
			}
		}
    }
}
</script>

<style scoped lang="scss">

</style>
